/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/styles/globals.css'

export const onClientEntry = () => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = 'https://booksy.com/widget/code.js?id=131383&country=pl&lang=pl'
  script.async = true
  document.body.appendChild(script)
}
